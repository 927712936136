
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { userState } from "../../atoms";
import { useRecoilValue } from "recoil";
import './style.css';
import { makeRequest } from "../../Utils";

export default function PublisherMenu() {
  const user = useRecoilValue(userState);
  const [logo, setLogo] = useState('');

  const getProfile = async () => {
    let data = await makeRequest('publisher?context=edit', 'GET');
    if (data.logo && data.logo['300']) {
      setLogo(data.logo['300']);
      window.localStorage.setItem('astPubLogo', data.logo['300']);
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('astPubLogo')) {
      setLogo(window.localStorage.getItem('astPubLogo'));
      return;
    }
    getProfile();
  }, [])

  return (
    <div className='pubMenu roomBelowLarge'>
      <div className='pubMenu__header'>
        <div className='pubMenu__image'>
          {logo && <img src={logo} />}
          <div className='pubMenu__name'>{user.name}</div>
        </div>
      </div>
      <div className='pubMenu__inner'>
        <Link className='pubMenuLink' to={'/pub/account'}>Home</Link>
        <Link className='pubMenuLink' to={'/pub/settings'}>Settings</Link>
        <Link className='pubMenuLink' to={'/pub/products'}>Products</Link>
        <Link className='pubMenuLink' to={'/pub/orders'}>Orders</Link>
        <Link className='pubMenuLink' to={'/pub/lists'}>Lists</Link>
        <Link className='pubMenuLink' to={'/pub/inventory'}>Inventory</Link>
        <Link className='pubMenuLink' to={'/pub/standing-orders'}>Standing Orders</Link>
        <Link className='pubMenuLink' to={'/pub/directory'}>Customer Directory</Link>

        <hr />
        <div className='pseudoLabel'>Tools</div>
        <Link className='pubMenuLink' to={'/tools/barcode-generator'}>Barcode Generator</Link>
        <Link className='pubMenuLink' to={'/tools/shipping'}>Shipping Calculator</Link>
        <Link className='pubMenuLink' to={'/tools/qr-code-generator'}>QR Code Generator</Link>
        {user.admin && <Link className='pubMenuLink' to={'/tools/labels'}>Label Maker</Link>}
        {user.admin && <Link className='pubMenuLink' to={'/tools/image-uploader'}>Image Uploader</Link>}
        {user.admin && <Link className='pubMenuLink' to={'/tools/newsletter-legacy'}>Newsletter Helper</Link>}
        {user.admin &&
          <div className='adminSubMenu'>
            <hr />
            <Link className='pubMenuLink' to={'/admin'}>Admin Home</Link>
            <Link className='pubMenuLink' to={'/admin/publishers'}>Publisher List</Link>
            <Link className='pubMenuLink' to={'/admin/products'}>Admin Products</Link>
            <Link className='pubMenuLink' to={'/tools/inventory'}>Inventory</Link>
            <Link className='pubMenuLink' to={'/admin/requests'}>Stock Requests</Link>
            <Link className='pubMenuLink' to={'/admin/logs'}>Logs</Link>
            <Link className='pubMenuLink' to={'/admin/tickets'}>Support Tickets</Link>
            <Link className='pubMenuLink' to={'/admin/bookstores'}>Bookstore DB</Link>
            <Link className='pubMenuLink' to={'/admin/universities'}>University Library DB</Link>
            <Link className='pubMenuLink' to={'/admin/libraries'}>Public Library DB</Link>
            <Link className='pubMenuLink' to={'/admin/orders'}>Admin Orders</Link>
            <Link className='pubMenuLink' to={'/admin/shipments'}>Admin Shipments</Link>
          </div>
        }
      </div>
    </div>
  )
}