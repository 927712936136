import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../icons/close.svg';
import { useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import { messageState, cartMessageState, cartOpenState, sessionState } from '../../atoms';
import { analyticsEvent, asaEvent } from '../../Utils';
import './style.css';

export default function Message() {
  const [message, setMessage] = useRecoilState(messageState);
  const clearMessage = useResetRecoilState(messageState);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const session = useRecoilValue(sessionState);

  useEffect(() => {
    if (message.temp) {
      setTimeout(() => {
        clearMessage()
      }, 3500)
    }
    if (message.cart) {
      asaEvent(session.id, session.group, 'cart', 'cartMessage', 'display', 1);
    }
  }, [message]);

  if (!message.text) {
    return (<></>)
  }

  return (
    <div className={`displayMessage ${message.type} ${message.classList}`}>
      {message.img && <div className='messageImage'><img src={message.img} alt='Book Cover Thumbnail' /></div>}
      <div className='messageInfo'>
        <div className='messageLabel'>{message.label ? message.label : message.type}</div>
        <div className='messageText' dangerouslySetInnerHTML={{ __html: message.text }}></div>
      </div>
      {!message.temp && !message.cart && <a className='messageClear' onClick={clearMessage}><Close /></a>}
      {message.cart &&
        <div className='messageButtons'>
          <button className='buttonPrimary-small' onClick={() => { setCartOpen(true); clearMessage(); analyticsEvent('cartClick', { location: 'message' }); asaEvent(session.id, session.group, 'cart', 'cartOpen', 'message', 1); }}>View Cart</button>
          <button className='buttonSecondary-small' onClick={() => { clearMessage(); asaEvent(session.id, session.group, 'cart', 'cartMessage', 'keepShopping', 1); }}>Keep Shopping</button>
        </div>
      }
    </div>
  )
}