import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userState, cartState, cartCountState, cartOpenState, menuOpenState, modeState, sessionState } from '../../atoms/';
import Cart from '../Cart';
import { ReactComponent as Wordmark } from '../../icons/wordmark_1.svg';
import './style.css';
import Search from '../Search';
import { analyticsEvent, asaEvent } from '../../Utils';

export default function Header() {
  const [user, setUser] = useRecoilState(userState);
  const cart = useRecoilValue(cartState);
  const cartCount = useRecoilValue(cartCountState);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const [menuOpen, setMenuOpen] = useRecoilState(menuOpenState);
  const mode = useRecoilValue(modeState);
  const session = useRecoilValue(sessionState);

  let location = useLocation();
  let navigate = useNavigate();

  const userId = useRef(null);

  useEffect(() => {
    if (userId.current && !user.id) {
      localStorage.setItem('asterismToken', '');
      if (location.pathname.includes('/account')) {
        navigate('/login');
      } else if (location.pathname.includes('/pub')) {
        navigate('/pub/login');
      }

    }
    userId.current = user.id;
  }, [user]);

  useEffect(() => {
    setMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location])

  const toggleCart = () => {
    setCartOpen(!cartOpen);
    if (!cartOpen) {
      analyticsEvent('cartClick', { location: 'header' });
      asaEvent(session.id, session.group, 'cart', 'cartOpen', 'header', 1);
    }
  }


  return (
    <div className='stickyContainer'>
      <header className='flex-layout headerContainer'>
        <div className='headerLogo'>
          <Link aria-label='Asterism Books Logo' className='headerLogo__link' tabIndex={0} to='/'><Wordmark className='headerLogo__image' /></Link>
        </div>
        <Search />
        <div className='headerRight'>

          <div className='userMenu'>
            <div className='userMenuHeader'>
              {user.email ?
                <button className='userMenuOpenButton' onClick={() => setMenuOpen(!menuOpen)}>
                  <i className='fa-solid fa-user'></i> <span className='mobileLabelHide'>My Account ↓</span>
                </button> :
                <span><Link tabIndex={0} to='/login'><i className='fa-solid fa-user'></i> <span className='mobileLabelHide'>Sign In</span></Link></span>}
            </div>
            {menuOpen && user.type === 'Publisher' && <PublisherMenu />}
            {menuOpen && user.type === 'Retailer' && <RetailerMenu />}
            {menuOpen && user.type === 'Customer' && <UserMenu />}
            {menuOpen && user.type === 'Library' && <LibraryMenu />}
          </div>
          <div className='cartCounterContainer'>
            <div className='cartCounter' onClick={toggleCart} role='button' tabIndex={0} aria-label='View Cart'>
              <i className='fa-solid fa-cart-shopping'></i>
              <span className='mobileLabelHide'>Cart</span>
              {cartCount > 0 &&
                <span className='cartCounterTag'>
                  <span className='cartCounterTag__no'>{cartCount}</span>
                </span>
              }</div>
          </div>
        </div>
      </header>
      <div className='subhead'>
        <nav className='subheadContainer'>
          <div className='subhead__list-left'>
            <Link to='/all-books'>All Books</Link>
            <Link to='/new-books'>New Releases</Link>
            <Link to='/upcoming-books'>Upcoming Titles</Link>
            <Link to='/ebooks'>eBooks</Link>
            <Link to='/all-books?onSale=true'>On Sale</Link>
            <Link to='/our-publishers'>Our Publishers</Link>
          </div>
          <div className='subhead__list-right'>
            <Link to='/about'>About</Link>
            <Link to='/customer-support'>Support</Link>
            <Link to='/for-retailers'>For Retailers</Link>
            <Link to='/for-libraries'>For Libraries</Link>
            <Link to='/for-publishers'>For Publishers</Link>
          </div>
        </nav>
      </div>
      <Cart open={cartOpen} />
      <div className={`cartScreen ${cartOpen && 'cartScreen-open'}`} onClick={() => { setCartOpen(false); asaEvent(session.id, session.group, 'cart', 'cartClose', 'offClick', 1); }}></div>
    </div>
  )
}

function PublisherMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);


  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name !== '' ? user.name : user.email}</li>
        <li><Link to='/pub/account'>Account Home</Link></li>
        <li><Link to='/pub/settings'>Account Settings</Link></li>
        <li><Link to='/pub/products'>My Products</Link></li>
        <li><Link to='/pub/orders'>My Orders</Link></li>
        <li><Link to='/pub/lists'>My Lists</Link></li>
        <li><Link to='/pub/standing-orders'>My Standing Orders</Link></li>
        <li><Link to='/pub/shipments'>My Shipments</Link></li>
        <li><Link to='/pub/directory'>Customer Directory</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function RetailerMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        <li><Link to='account/standing-orders'>Standing Orders</Link></li>
        <li><Link to='/account/new-order'>Quick Order Tool</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function UserMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name ? user.name : user.email}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='account/wishlist'>My Wishlist</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        {user.admin && <li><Link to='/admin'>Admin</Link></li>}
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}

function LibraryMenu() {
  const logout = useResetRecoilState(userState);
  const user = useRecoilValue(userState);

  return (
    <div className='userMenu__expanded'>
      <ul>
        <li>Logged in as {user.name}</li>
        <li><Link to='/account'>Account Settings</Link></li>
        <li><Link to='account/standing-orders'>Standing Orders</Link></li>
        <li><Link to='/account/orders'>My Orders</Link></li>
        <li><a onClick={logout} role='button'>Log out</a></li>
      </ul>
    </div>
  )
}
