import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { cartState, messageState, modeState, sessionState } from '../../atoms';
import { canOrderProduct, returnPrice, isOnSale, prepareAuthorList, analyticsEvent, makeRequest, updatedCart, asaEvent } from '../../Utils';

export default function Bestsellers() {
  const [lists, setLists] = useState([]);
  const [first, setFirst] = useState({});
  const [cart, setCart] = useRecoilState(cartState);
  const setMessage = useSetRecoilState(messageState);
  const session = useRecoilValue(sessionState);
  const mode = useRecoilValue(modeState);

  const getLists = async () => {
    let data = await makeRequest('bestsellers');
    setLists(data);
    let slug = data[0].slug;
    let request = await makeRequest(`list?slug=${slug}`);
    setFirst(request);
  }

  document.title = 'Bestsellers | Asterism Books'
  document.querySelector('meta[name="description"]').setAttribute('content', `Weekly lists of the bestsellers at Asterism Books`);

  useEffect(() => {
    getLists();
  }, []);

  const addToCart = (product) => {
    setCart(updatedCart(cart, product));
    setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'], temp: false, cart: true });
    analyticsEvent('addToCart', { product: product._id, location: 'productList', mode: mode });
    asaEvent(session.id, session.group, 'cart', `addToCart-bestsellers`, product._id, 1);
  }

  return (
    <div>
      {lists && lists.map((list, index) => {
        if (index === 0) {
          return (<div key={list._id} className='bestsellerCard'>
            <div className='bestsellerHeader roomAboveLarge roomBelowLarge'><h1 className='page-header'><Link to={`/list/${list.slug}`}>{list.title}</Link></h1></div>
            {first.products && first.products.map((product, index) => {
              return (
                <div key={product._id} className='bestsellerItem'>
                  <div className='bestsellerNo'><span className='bestsellerDigit'>{index + 1}</span></div>
                  <div className='bestsellerCover'>
                    <img src={product.cover['300']} alt={`Cover for ${product.title}`} />
                  </div>
                  <div className='bestsellerData'>
                    <div className='bestsellerDataContainer'>
                      <div className='bestsellerTitle'><Link className='' to={`/product/${product.slug}`}>{product.title}</Link></div>
                      <div className='bestsellerAuthor'>{prepareAuthorList(product)}</div>
                    </div>
                  </div>
                  <div className='bestsellerData'>
                    <div className='bestsellerDataContainer'>
                      <div className='bestsellerAuthor'><Link to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link></div>
                    </div>
                  </div>
                  <div className='bestsellerData'>
                    <div className='bestsellerDataContainer'>
                      <button className='bestsellerCart' disabled={!canOrderProduct(product, mode)} onClick={() => { addToCart(product) }}>Add to Cart <i className='fa-solid fa-plus'></i></button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>)
        } else {
          return (<div key={list._id} className='asterismCard bestsellerCard'>
            <h4><Link to={`/list/${list.slug}`}>{list.title}</Link></h4>
          </div>)
        }
      }

      )}
    </div>
  )
}