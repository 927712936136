import { formatISODate, makeRequest, returnPrice, isPreorder, getEffectiveInventory, returnISODate, getActualInventory, isDigitalItem } from "../../Utils";
import moment from 'moment';

const adjustDateForWeekend = (maybeDay) => {
  try {
    let day = maybeDay.weekday();
    if (day === 0) {
      return maybeDay.add('1', 'days')
    } else if (day === 7) {
      return maybeDay.add('2', 'days')
    } else {
      return maybeDay;
    }
  } catch (e) {
    return moment();
  }
}

export const isCartAllDigital = (cart) => {
  let digital = true;
  Object.values(cart).forEach((product) => {
    if (!isDigitalItem(product.data.format)) {
      digital = false;
    }
  });
  return digital;
}

export const isCartAnyDigital = (cart) => {
  let digital = false;
  Object.values(cart).forEach((product) => {
    if (isDigitalItem(product.data.format)) {
      digital = true;
    }
  });
  return digital;
}

/**
 * 
 * @param {object} product Product data
 * @param {string} mode Order mode
 * @param {number} quantity Number of products
 * @returns String representing ISO String of ship date
 */
const approximateShipDate = (product, mode, quantity) => {
  let date = ''

  let effective = getEffectiveInventory(product, mode);
  let isBackorder = !isPreorder(product) && !getActualInventory(product);

  if (isBackorder) { // On backorder, explicitly
    let maybeDate = moment().add(21, 'days');
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (effective >= quantity && !isPreorder(product)) { // We have it and it's out now
    let maybeDate = moment();
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (effective >= quantity && isPreorder(product)) { // Have it and it's a preorder
    let maybeDate = moment().add(10, 'days').isAfter(moment(product.publicationDate))
      ? moment()
      : moment(product.publicationDate).subtract(5, 'days');
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (isPreorder(product)) { // Don't have it and it's a preorder so assume pub day
    let maybeDate = moment(product.publicationDate);
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (product.inventory.printOnDemand) { // Can be printed on-demand so let's add a few days
    let maybeDate = moment().add(4, 'days');
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else { // Else it's on backorder and we best be able to get it within 3 weeks
    let maybeDate = moment().add(21, 'days').toISOString();
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  }

  return date;
}

/**
 * This is the big important function
 * @param {number} total The total amount in the cart
 * @param {*} cart The cart data
 * @param {number} shipping Calculated total for shipping
 * @param {number} ounces Total weight of books in cart
 * @param {string} mode Either retail | wholesale | library | publisher
 * @param {boolean} consolidate Whether or not to consolidate shipments
 * @param {string} country Two-letter country code
 * @param {number} setPrice If the product has a set price outside of the normal modes calculation (only works for single-book orders)
 * @returns 
 */
export const prepareShipmentsAndSplits = (total, cart, shipping, ounces, mode, consolidate, country, coupon, collect, setPrice = null) => {
  let items = Object.values(cart);
  let payments = {
    asterism: 0,
  };

  const allShipments = {};

  items.forEach((item) => {
    let product = item.data;
    if (!payments[product.publisher]) {
      payments[product.publisher] = 0;
    }
    let count = item.count;
    let weight = product.dimensions ? product.dimensions.weight : 10;
    let price = setPrice ?? returnPrice(product, mode, coupon, mode === 'wholesale' && collect, item.isUsed);
    let shipDateTime = approximateShipDate(product, mode, count);
    let shipDate = returnISODate(shipDateTime);

    let fulfillNow = count <= product.inventory.asterism;
    let status = fulfillNow ? 'Ready' : isPreorder(product) ? 'Preorder' : 'Backorder';
    payments[product.publisher] += price * count * .76;

    if (!isDigitalItem(product.format)) {
      allShipments[`asterism%${shipDate}`] = allShipments[`asterism%${shipDate}`] || { items: {}, weight: 0, status: status };
      allShipments[`asterism%${shipDate}`].items[product._id] = count;
      allShipments[`asterism%${shipDate}`].weight += (count * weight);
    }
  });

  let shipments = allShipments;
  let totalPayouts = getTotalPayouts(payments);
  let asterismCut = total - totalPayouts;
  payments.asterism = payments.asterism ? payments.asterism : 0;
  payments.asterism += asterismCut;

  return {
    payments: payments,
    shipments: formatShipments(shipments),
    shippingSplit: { 'asterism': shipping },
  }
}

/**
 * 
 * @param {*} shipments 
 * @returns 
 */
const formatShipments = (shipments) => {
  let formatted = [];
  let shippers = Object.keys(shipments);
  let shipmentData = Object.values(shipments);
  for (let i = 0; i < shippers.length; i++) {
    let bits = shippers[i].split('%');
    formatted.push({
      from: bits[0],
      items: shipmentData[i].items,
      asterism: shipmentData[i].asterism,
      weight: shipmentData[i].weight,
      status: shipmentData[i].status,
      shipDate: bits[1],
      created: formatISODate(new Date()),
      tracking: ''
    })
  }
  return formatted;
}

export const getTotalPayouts = (payments) => {
  return Object.values(payments).reduce((partialSum, a) => partialSum + a, 0);
}

export const prepareItems = (cart, mode, setPrice = null, prepay = false, coupon = null) => {
  let items = { ...cart };
  let itemIds = Object.keys(items);
  itemIds.forEach((id) => {
    let product = items[id].data;

    let salePrice = setPrice ?? returnPrice(product, mode, coupon, prepay, items[id].isUsed);
    let item = {
      title: `${product.title}`,
      authors: `${product.authors.join(', ')}`,
      isPreorder: isPreorder(product),
      quantity: items[id].count,
      listPrice: product.prices.retail,
      salePrice: salePrice,
      publisher: product.publisher,
      digital: isDigitalItem(product.format),
      used: items[id].isUsed,
      oneOff: items[id].oneOff,
    }
    items[id] = item;
  })
  return items;
}

export const refreshCartData = async (cart, mode, admin) => {
  const ids = Object.keys(cart);
  const items = {};
  console.log(ids);

  for (let i = 0; i < ids.length; i++) {
    try {
      const data = await makeRequest(`product?id=${ids[i]}`, 'GET');
      let inventory = getEffectiveInventory(data, admin ? 'wholesale' : mode);
      console.log('Heeeere');
      items[ids[i]] = {
        count: cart[ids[i]].count >= inventory ? inventory : cart[ids[i]].count,
        data: data
      }
      if (!isDigitalItem(data.format) && items[ids[i]].count === 0 && !admin) {
        console.log('Deleted for here')
        delete items[ids[i]];
      }
    } catch (e) {
      console.log('Deleted due to error');
      delete items[ids[i]];
    }
  }
  return items;
}

export const distinctShipDates = (cart, mode) => {
  let dates = [];
  let items = Object.values(cart);
  items.forEach((item) => {
    let product = item.data;
    let shipDate = approximateShipDate(product, mode, item.count);
    dates.push(shipDate);
  });
  const uniqueDates = [...new Set(dates)];
  return uniqueDates.length;
}


export const preparePayments = async (total, cart, shipping, ounces, mode) => {
  let items = Object.values(cart);

  let payments = {};

  items.forEach((item) => {
    let product = item.data;
    if (!payments[product.publisher]) {
      payments[product.publisher] = 0;
    }
    let count = item.count;
    let asterism = product.inventory.asterismEnabled;
    // Get the price of the item!
    let price = returnPrice(product, mode);
    if (asterism) {
      // Who can fulfill?
      let asterismCanFulfill = count <= product.inventory.asterism;
      let publisherCanFulfill = count <= product.inventory.publisher;
      let canBothFulfill = count <= (product.inventory.asterism + product.inventory.publisher);
      // End who can fulfill
      if (asterismCanFulfill) {
        payments[product.publisher] += price * count * .76;
      } else if (publisherCanFulfill) {
        payments[product.publisher] += price * count * .88;
      } else if (canBothFulfill) {
        payments[product.publisher] += price * product.inventory.asterism * .76;
        payments[product.publisher] += price * (count - product.inventory.asterism) * .76;
      }
    } else {
      payments[product.publisher] += price * count * .88;
    }
  });

  let cartTotal = total + shipping;
  let totalPayouts = getTotalPayouts(payments);

  let asterismCut = cartTotal - totalPayouts;
  payments.asterism = asterismCut;

  return payments;
}

export const getExtraShipments = (cart, mode, country) => {
  let items = Object.values(cart);
  let shippers = [];
  const allShipments = {};

  items.forEach((item) => {
    let product = item.data;
    let viaAsterism = country !== 'US' && country !== '' && !product.inventory.printOnDemand;
    let count = item.count;
    let weight = product.dimensions ? product.dimensions.weight : 10;
    let asterism = product.inventory.asterismEnabled;
    // Get the price of the item!
    let shipDateTime = approximateShipDate(product, mode, count);
    let shipDate = returnISODate(shipDateTime);
    if (asterism) {
      // Who can fulfill?
      let asterismCanFulfill = count <= product.inventory.asterism;
      let publisherCanFulfill = count <= product.inventory.publisher;
      let canBothFulfill = count <= (product.inventory.asterism + product.inventory.publisher);
      // End who can fulfill
      if (asterismCanFulfill) {
        shippers.push('asterism');
        allShipments[`asterism%${shipDate}`] = allShipments[`asterism%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`asterism%${shipDate}`].items[product._id] = count;
        allShipments[`asterism%${shipDate}`].weight += (count * weight);
      } else if (publisherCanFulfill && viaAsterism) {
        shippers.push('asterism');
        allShipments[`asterism%${shipDate}`] = allShipments[`asterism%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`asterism%${shipDate}`].items[product._id] = count;
        allShipments[`asterism%${shipDate}`].weight += (count * weight);
      } else if (publisherCanFulfill) {
        shippers.push(product.publisher);
        allShipments[`${product.publisher}%${shipDate}`] = allShipments[`${product.publisher}%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`${product.publisher}%${shipDate}`].items[product._id] = count;
        allShipments[`${product.publisher}%${shipDate}`].weight += (count * weight);
      }
      else if (canBothFulfill && viaAsterism) {
        shippers.push(product.publisher);
        shippers.push('asterism');

        allShipments[`asterism%${shipDate}`] = allShipments[`asterism%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`asterism%${shipDate}`].items[product._id] = count;
        allShipments[`asterism%${shipDate}`].weight += count * weight;

      } else if (canBothFulfill) {
        shippers.push(product.publisher);
        shippers.push('asterism');

        allShipments[`asterism%${shipDate}`] = allShipments[`asterism%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`asterism%${shipDate}`].items[product._id] = product.inventory.asterism;
        allShipments[`asterism%${shipDate}`].weight += (product.inventory.asterism * weight);

        allShipments[`${product.publisher}%${shipDate}`] = allShipments[`${product.publisher}%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`${product.publisher}%${shipDate}`].items[product._id] = count - product.inventory.asterism;
        allShipments[`${product.publisher}%${shipDate}`].weight += ((count - product.inventory.asterism) * weight);
      } else if (product.inventory.printOnDemand) {
        shippers.push(product.publisher);
        allShipments[`${product.publisher}%${shipDate}`] = allShipments[`${product.publisher}%${shipDate}`] || { items: {}, weight: 0, status: 'Ready' };
        allShipments[`${product.publisher}%${shipDate}`].items[product._id] = count;
        allShipments[`${product.publisher}%${shipDate}`].weight += (count * weight);
      } else {
        shippers.push('asterism');

        allShipments[`asterism%${shipDate}`] = allShipments[`asterism%`] || { items: {}, weight: 0, status: 'Backorder' };
        allShipments[`asterism%${shipDate}`].items[product._id] = count;
        allShipments[`asterism%${shipDate}`].weight += (count * weight);
      }
    } else if (viaAsterism) {
      shippers.push('asterism');

      allShipments[`asterism%${shipDate}`] = allShipments[`asterism%`] || { items: {}, weight: 0, status: 'Backorder' };
      allShipments[`asterism%${shipDate}`].items[product._id] = count;
      allShipments[`asterism%${shipDate}`].weight += (count * weight);
    } else {
      shippers.push(product.publisher);

      let publisherCanFulfill = count <= product.inventory.publisher;
      let shipmentStatus = (publisherCanFulfill || product.inventory.printOnDemand || isPreorder(product)) ? 'Ready' : 'Backorder';
      allShipments[`${product.publisher}%${shipDate}`] = allShipments[`${product.publisher}%${shipDate}`] || { items: {}, weight: 0, status: shipmentStatus };
      allShipments[`${product.publisher}%${shipDate}`].items[product._id] = count;
      allShipments[`${product.publisher}%${shipDate}`].weight += (count * weight);
    }
  });

  shippers = [...new Set(shippers)];
  let shipments = formatShipments(allShipments);
  return shipments.length - shippers.length;
}


export const getShippers = (cart) => {
  let items = Object.values(cart);
  let shippers = [];

  items.forEach((item) => {
    let product = item.data;
    let count = item.count;
    let asterism = product.inventory.asterismEnabled;
    if (asterism) {
      let asterismCanFulfill = count <= product.inventory.asterism;
      let publisherCanFulfill = count <= product.inventory.publisher;
      let canBothFulfill = count <= (product.inventory.asterism + product.inventory.publisher);
      // End who can fulfill
      if (asterismCanFulfill) {
        shippers.push('asterism');
      } else if (publisherCanFulfill) {
        shippers.push(product.publisher);
      } else if (canBothFulfill) {
        shippers.push(product.publisher);
        shippers.push('asterism');
      } else if (product.inventory.printOnDemand) {
        shippers.push(product.publisher);
      } else {
        shippers.push('asterism');
      }
    } else {
      shippers.push(product.publisher);
    }
  });

  shippers = [...new Set(shippers)];
  return shippers;
}