import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import ProductListItem from '../../components/ProductListItem';
import { makeRequest, Loading } from '../../Utils';
import { useRecoilValue } from 'recoil';
import { sessionState } from '../../atoms';
import GhostGrid from '../../components/GhostGrid';

export default function BooksAll() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const session = useRecoilValue(sessionState);

  document.title = 'Search Results | Asterism Books';
  document.querySelector('meta[name="description"]').setAttribute('content', 'Search results.');

  const location = useLocation();

  const search = async (query) => {
    console.log(query);
    if (!query) {
      return;
    }
    setLoading(true);
    setProducts([]);
    let data = await makeRequest(`search?term=${query}`);
    setProducts(data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    let params = new URLSearchParams(window.location.search);
    let searchQuery = params.get('query');
    if (searchQuery) {
      setQuery(searchQuery);
    } else {
      setLoading(false)
    }
  }, [location]);

  useEffect(() => {
    if (query) {
      search(query);
      document.title = `Search | Asterism Books`;
      document.querySelector('meta[name="description"]').setAttribute('content', `Search results from Asterism Books`);
    }
  }, [query])


  return (
    <div>
      <div className='flex-layout'>
        <h1 className='page-header'>{loading ? `Searching for "${query}"` : `Search results for "${query}"`}</h1>
      </div>
      {!loading && products.length === 0 && <div>No Results</div>}

      <div className='lowerGrid'>
        {loading && <GhostGrid />}
        {products.map((product) =>
          <ProductListItem key={product._id} product={product} context={'search'} />
        )}
      </div>

    </div>
  )
}
