import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userState, cartState, modeState, messageState, cartOpenState, sessionState } from '../../atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { makeRequest, isPreorder, returnPrice, slugify, getInventory, canOrderProduct, getEffectiveInventory, prettyIsbn, wishlistChange, analyticsEvent, isDigitalItem, formatNiceName, updatedCart, asaEvent } from '../../Utils';
import moment from 'moment';
import ProductListItem from '../../components/ProductListItem';
import PreviewModal from '../../components/PreviewModal';
import './style.css';
import showdown from 'showdown';
import GhostGrid from '../../components/GhostGrid';
const converter = new showdown.Converter();


export default function Product() {
  const [product, setProduct] = useState({});
  const [cart, setCart] = useRecoilState(cartState);
  const setCartOpen = useSetRecoilState(cartOpenState);
  const [inventory, setInventory] = useState(0);
  const user = useRecoilValue(userState);
  const mode = useRecoilValue(modeState);
  const [contribs, setContribs] = useState([]);
  const [price, setPrice] = useState(0);
  const [related, setRelated] = useState([]);
  const setMessage = useSetRecoilState(messageState);
  const [preview, setPreview] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [canOrder, setCanOrder] = useState(true);
  const [digital, setDigital] = useState(false);
  const [variants, setVariants] = useState([]);
  const session = useRecoilValue(sessionState);

  const navigate = useNavigate();

  const { slug } = useParams();

  const getRelated = async (id) => {
    let data = await makeRequest(`related-products?id=${id}&group=${session.group}`, 'GET');
    setRelated(data);
  }

  useEffect(() => {
    console.log(user);
  }, [user])

  const getVariants = async (id) => {
    let v = await makeRequest(`variants?id=${id}`);
    setVariants(v);
  }

  const checkParams = () => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('preview') === 'true') {
      setPreview(true);
    }

    if (params.get('addToCart') === 'true') {
      let qty = params.get('qty') ? parseInt(params.get('qty')) : 1;
      addToCart(qty, true);
      params.delete('addToCart');
      params.delete('qty');
    }
  }

  const getProduct = async (slug) => {
    setRelated([]);
    let data = await makeRequest(`product-by-slug?slug=${slug}`, 'GET');
    if (!data._id) {
      navigate('/404');
    }
    document.title = `${data.title} from ${data.publisherData.name} | Asterism Books`;
    document.querySelector('meta[name="description"]').setAttribute('content', `${data.description}`);

    setProduct(data);
    setPrice(returnPrice(data, preview ? 'retail' : mode));
    setInventory(getInventory(data));
    getRelated(data._id);
    getVariants(data._id);
  }

  useEffect(() => {
    if (slug) {
      setProduct({});
      getProduct(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (product.title) {
      checkParams();
    }
    if (product.authors) {
      prepareProductPeople();
    }
    if (product.format) {
      setDigital(isDigitalItem(product.format));
    }
  }, [product]);

  useEffect(() => {
    if (!product._id) {
      return;
    }
    setCanOrder(canOrderProduct(product, mode));
  }, [product, mode])

  if (!product.title) {
    return (<div></div>)
  }

  const prepareProductPeople = () => {
    let people = [];
    product.translators.forEach((person) => {
      people.push({
        name: person,
        role: 'translator'
      })
    });
    product.editors.forEach((person) => {
      people.push({
        name: person,
        role: 'editor'
      })
    });
    product.illustrators.forEach((person) => {
      people.push({
        name: person,
        role: 'illustrator'
      })
    });
    product.artists.forEach((person) => {
      people.push({
        name: person,
        role: 'artist'
      })
    });
    if (product.contributors) {
      product.contributors.forEach((person) => {
        people.push({
          name: person,
          role: 'contributor'
        })
      });
    }
    setContribs(people);
  }

  const addToCart = (qty = 1, open = false) => {
    setCart(updatedCart(cart, product, qty));
    if (open) {
      setCartOpen(true);
    } else {
      setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com'), temp: true, cart: true })
    }
    analyticsEvent('addToCart', { product: product._id, location: 'productPage', mode: mode });
    asaEvent(session.id, session.group, 'cart', 'addToCart', product._id, qty);
  }

  const returnProductDescription = () => {
    let html = converter.makeHtml(product.description);
    let externalized = html.replace(/<a/g, '<a target="_blank"');
    return externalized;
  }

  return (
    <div>
      <div className='flex-layout flexColMobile'>
        <div className='flex-two'>
          <div className='productPageImage'>
            <img src={product.cover['900'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={`${product.title} book cover`} />
          </div>
          {product.preview && <button className='previewButton' onClick={() => { setPreviewing(true) }}><i className='fa-solid fa-eye'></i> Preview This Title</button>}
          {previewing && <PreviewModal product={product} close={() => { setPreviewing(false) }} />}
        </div>
        <div className='flex-four'>
          <div className='productPageHeader'>

            <div className='productPageHeader__stats'>
              <span>{moment(product.publicationDate).format('LL')} | </span>
              <span><Link className='productPageHeader__publisher' to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link> | </span>
              <span>{prettyIsbn(product._id)}</span>
              {product.seriesNo && <span> | No. {product.seriesNo} in {product.series}</span>}
              {(product.publisher === user.id || user.admin) && !preview && <span> | <Link to={`/pub/product/${product._id}`}>Edit</Link></span>}
            </div>
            <h1 className='productPageHeader__title'>{product.title}</h1>
            {product.subtitle && <div className='productPageHeader__subtitle'>{product.subtitle}</div>}
            <div className='productPageHeader__authors'>
              {product.authors.map((user, index) =>
                <span key={user} className='productPageHeader__author'>{user}{index !== product.authors.length - 1 && ', '}</span>
              )}

              {product.authors.length > 0 && contribs.length > 0 && <span> | </span>}

              {contribs.map((person, index) =>
                <span key={user.name}>{person.name} ({person.role}){index !== contribs.length - 1 && ', '}</span>
              )}

            </div>
          </div>

          <div className='productPricing'>
            <div className='productPrice'>
              {price !== product.prices.retail && <span className='productPrice__list'>${product.prices.retail.toFixed(2)}</span>}
              ${price.toFixed(2)}
            </div>
            <div className='productPricing__control'>
              {mode === 'wholesale' && canOrder &&
                <div className='flex-layout flex-layout-small'>
                  <button className='buttonPrimary' onClick={() => { addToCart(1) }}>Add 1 to Order</button>
                  <button className='buttonSecondary' onClick={() => { addToCart(2) }}>+ 2</button>
                  <button className='buttonSecondary' onClick={() => { addToCart(5) }}>+ 5</button>
                  <button className='buttonSecondary' onClick={() => { addToCart(10) }}>+ 10</button>
                </div>
              }
              {mode !== 'wholesale' && canOrder && <button className={`buttonPrimary buttonPrimary-red`} onClick={() => { addToCart(1) }}>{isPreorder(product) ? 'Pre-order' : 'Add to Cart'}</button>}
              {!canOrder && <div className='outOfStock'>Out of Stock</div>}
              {mode !== 'wholesale' && <button onClick={() => {
                asaEvent(session.id, session.group, 'wishlist', 'wishlistClick', product._id);
                if (user.id) {
                  wishlistChange(user.id, product._id, true);
                  setMessage({ type: 'success', label: 'Added to Wishlist', text: `<strong>${product.title}</strong> has been added to your wishlist!`, temp: true })
                } else {
                  navigate(`/login?wishlist=${product._id}`);
                }
              }} className='roomLeftMedium buttonSecondary buttonSecondary-red'>Add to Wishlist</button>}
            </div>
            {mode === 'retail' && product.pubLink && product.pubLink.length > 0 &&
              <div className='productPricing__pubLink'>
                <a target='_blank' rel="noreferrer" onClick={() => {
                  asaEvent(session.id, session.group, 'pubLink', 'click', product._id);
                }} href={product.pubLink}>Or buy this book directly from {product.publisherData.name}</a>
              </div>}
            {mode === 'wholesale' &&
              <div className='productPricing__inventory'>
                {inventory > 0 && <span>{inventory} in stock now.</span>}
                {getEffectiveInventory(product, mode) <= 0 && <span>Product not available.</span>}
                {isPreorder(product) && canOrder && <span>Product is available to pre-order.</span>}
                {!isPreorder(product) && canOrder && product.inventory.backorderEnabled && !product.inventory.printOnDemand && <span>Product can be backordered.</span>}
              </div>
            }
          </div>

          {variants && variants.length > 0 &&
            <div>
              <h4 className='variantsHeader'>This product has multiple versions</h4>
              <div className='variants'>
                <div className='variant current'>
                  <div className='variantTitle'>{formatNiceName(product.format)}</div>
                  <div className='variantPrice'>${returnPrice(product, mode).toFixed(2)}</div>
                </div>
                {variants.map((variant) =>
                  <Link to={`/product/${variant.slug}`}>
                    <div className='variant'>
                      <div className='variantTitle'>{formatNiceName(variant.format)}</div>
                      <div className='variantPrice'>${returnPrice(variant, mode).toFixed(2)}</div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          }

          {/*(product.publisher == user.id || user.admin) && !preview && <div className='pubPrices'>
            <div className='pubPrice__label'>This is your product. Here's your pricing breakdown by user type:</div>
            <div className='flex-layout'>
              <div className='flex-one'>
                <div className='pseudoLabel'>List Price</div>
                <div>${product.prices.retail.toFixed(2)}</div>
              </div>
              <div className='flex-one'>
                <div className='pseudoLabel'>Retail Price</div>
                <div>${returnPrice(product, 'retail').toFixed(2)}</div>
              </div>
              {!digital && <div className='flex-one'>
                <div className='pseudoLabel'>Library/Pub Price</div>
                <div>${returnPrice(product, 'library').toFixed(2)}</div>
              </div>}
              {!digital && <div className='flex-one'>
                <div className='pseudoLabel'>Wholesale Price</div>
                <div>${returnPrice(product, 'wholesale').toFixed(2)}</div>
              </div>}
            </div>
          </div>*/}

          <div className='productDescription' dangerouslySetInnerHTML={{ __html: returnProductDescription() }}></div>

          <div className='productStats'>
            <div className='productStat'>
              <div className='productStat__label'>Format</div>
              <div className='productStat__data'>{formatNiceName(product.format)}</div>
            </div>
            {product.pages && <div className='productStat'>
              <div className='productStat__label'>Pages</div>
              <div className='productStat__data'>{product.pages}</div>
            </div>}
            {!digital && <div className='productStat'>
              <div className='productStat__label'>Dimensions</div>
              <div className='productStat__data'>{product.dimensions.height}"x{product.dimensions.width}"x{product.dimensions.thickness}"</div>
            </div>}
            {!digital && <div className='productStat'>
              <div className='productStat__label'>Weight</div>
              <div className='productStat__data'>{product.dimensions.weight} oz.</div>
            </div>}
          </div>
          <div className='categoryList'>
            {product.categories.filter((choice) => { return choice !== null && choice.value && choice.label; }).map((category) =>
              <Link className='categoryLink' key={category.value} to={`/all-books?categories=${category.value}`}>{category.label}</Link>
            )}
          </div>
        </div>
      </div>
      <div className=''>
        <h3>Related Products</h3>
        <div className='lowerGrid'>
          {related.length === 0 && <GhostGrid />}
          {related.map((prod) =>
            <ProductListItem key={prod._id} product={prod} context={'related'} />
          )}
        </div>
      </div>
    </div >
  )
}
