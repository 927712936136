import {
  atom,
  selector
} from 'recoil';
import { returnPrice } from '../Utils';

export const userState = atom({
  key: 'userState',
  default: {
    role: 'Guest',
    name: '',
    email: '',
    id: null,
    admin: false,
  },
});

export const cartState = atom({
  key: 'cartState',
  default: {}
});

export const checkoutMode = atom({
  key: 'checkoutMode',
  default: 'default',
});

export const messageState = atom({
  key: 'messageState',
  default: {}
})

export const cartMessageState = atom({
  key: 'cartMessageState',
  default: {}
})

export const cartOpenState = atom({
  key: 'cartOpenState',
  default: false,
});

export const menuOpenState = atom({
  key: 'menuOpenState',
  default: false,
});

export const testGroupState = atom({
  key: 'testGroupState',
  default: null,
});

export const payPalCheckedOutState = atom({
  key: 'payPalCheckedOutState',
  default: false,
})

export const couponState = atom({
  key: 'couponState',
  default: {}
});

export const sessionState = atom({
  key: 'sessionState',
  default: {
    id: null,
    testGroup: null,
    country: null,
    browserwidth: null,
  }
});

export const dragDataState = atom({
  key: 'dragDataState',
  default: {
    type: null,
    groupIndex: null,
    itemIndex: null,
    data: null
  }
})

export const isJState = selector({
  key: 'isJState',
  get: ({ get }) => {
    const user = get(userState)
    return user.id && user.id === 'ffe295f2bd48647c7f354d7cb024bc24';
  },
});

export const cartCountState = selector({
  key: 'cartCountState',
  get: ({ get }) => {
    const filter = get(cartState);
    const user = get(userState)

    let total = 0;
    const items = Object.values(filter);
    for (let i = 0; i < items.length; i++) {
      total += items[i].count;
    }
    return total;
  },
});

export const cartTotalState = selector({
  key: 'cartTotalState',
  get: ({ get }) => {
    const cart = get(cartState);
    const mode = get(modeState);
    const coupon = get(couponState);

    const items = Object.values(cart);
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      let product = items[i].data;
      let count = items[i].count;
      total += count * returnPrice(product, mode, coupon, false, items[i].isUsed);
    }
    return total;
  },
});

export const cartWeightState = selector({
  key: 'cartWeightState',
  get: ({ get }) => {
    const cart = get(cartState);
    const items = Object.values(cart);
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      let product = items[i].data;
      total += items[i].count * product.dimensions ? product.dimensions.weight : 10;
    }
    return Math.ceil(total / 16);
  },
});

export const cartWeightOuncesState = selector({
  key: 'cartWeightOuncesState',
  get: ({ get }) => {
    const cart = get(cartState);
    const items = Object.values(cart);
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      let product = items[i].data;
      total += items[i].count * (product.dimensions ? product.dimensions.weight : 10);
    }
    return total;
  },
});

export const modeState = selector({
  key: 'modeState',
  get: ({ get }) => {
    const user = get(userState)
    return user.type == 'Retailer' ? 'wholesale' : user.type == 'Library' ? 'library' : user.type == 'Publisher' ? 'publisher' : 'retail';
  },
});