import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Link, useLocation } from 'react-router-dom';
import { userState, cartState, cartOpenState, cartTotalState, cartCountState, couponState, cartWeightOuncesState, modeState, messageState, checkoutMode, payPalCheckedOutState, sessionState } from '../../atoms/';
import { makeRequest, returnPrice, validateAddress, validateEmail, getEffectiveInventory, analyticsEvent, getProduct, isDigitalItem, asaEvent } from '../../Utils';
import { TextControl, BinaryControl, CustomerControl } from '../InputControls';
import { SearchControl } from '../Search';
import AddressForm from '../AddressForm';
import LoginForm from '../LoginForm';
import { prepareShipmentsAndSplits, prepareItems, getExtraShipments, refreshCartData, isCartAllDigital, isCartAnyDigital } from './cartUtils';
import { defaultOrder } from '../../Defaults';
import { ReactComponent as Close } from '../../icons/close.svg';
//import PayPal from './PayPal';

import './style.css';

export default function Cart({ open = false }) {
  const user = useRecoilValue(userState);
  const [cart, setCart] = useRecoilState(cartState);
  const total = useRecoilValue(cartTotalState);
  const cartCount = useRecoilValue(cartCountState);
  const ounces = useRecoilValue(cartWeightOuncesState);
  const mode = useRecoilValue(modeState);
  const setMessage = useSetRecoilState(messageState);
  const [uploading, setUploading] = useState(false);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const [shipping, setShipping] = useState(0);
  const [shippingMethod, setShippingMethod] = useState('')
  const [loggingIn, setLoggingIn] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [packages, setPackages] = useState(1);
  const [consolidateShipments, setConsolidateShipments] = useState(false);
  const [collect, setCollect] = useState(true);
  const [ref, setRef] = useState('');
  const [express, setExpress] = useState(false);
  const [phone, setPhone] = useState('');
  const [fetching, setFetching] = useState(false);
  const [customer, setCustomer] = useState('');
  const [customerData, setCustomerData] = useState({});
  const [coupon, setCoupon] = useRecoilState(couponState);
  const [code, setCode] = useState('');
  const [codeStatus, setCodeStatus] = useState('');
  const [freeShipping, setFreeShipping] = useState(false);
  const checkoutState = useRecoilValue(checkoutMode);
  const [newId, setNewId] = useState('');
  const [address, setAddress] = useState({
    country: 'US'
  });
  const [allDigital, setAllDigital] = useState(false);
  const [payPalId, setPayPalId] = useRecoilState(payPalCheckedOutState);
  const [preparedItems, setPreparedItems] = useState({});
  const session = useRecoilValue(sessionState);

  const debounceDelay = 750;
  const debounceTimer = useRef(0);
  const controller = useRef();


  useEffect(() => {
    let items = prepareItems(cart, customerData.type ? returnUserType(customerData) : mode, null, false, coupon);
    setPreparedItems(items);
  }, [customerData, mode, coupon, cart]);

  /**
   * Gets the customer info if we have a logged in user with an ID
   */
  const getCustomer = async () => {
    let customerInfo = await makeRequest(`customer?id=${customer}`, 'GET');
    if (!customerInfo.error) {
      setCustomerData(customerInfo);
      setEmail(customerInfo.email);
      setCollect(false);
      setAddress(customerInfo.address);
      setPhone(customerInfo.contact.phone);
    }
  }

  useEffect(() => {
    if (customer) {
      getCustomer();
    }
  }, [customer])

  const location = useLocation();

  useEffect(() => {
    setCartOpen(false);
    if (cartOpen) {
      asaEvent(session.id, session.group, 'cart', 'cartClose', 'navigated', 1);
    }
  }, [location])

  /**
   * Removes an item from the cart
   * @param {string} id 
   */
  const removeItem = (id) => {
    let items = { ...cart };
    delete items[id];
    setCart(items);
  }

  const updateQuantity = (id, quantity) => {
    let items = { ...cart };
    let item = { ...items[id] };
    item.count = quantity;
    items[id] = item;
    setCart(items);
  }

  useEffect(() => {
    if (cartOpen) {
      analyticsEvent('cartOpen', { mode: mode })
      getDefaultAddress();
    }
  }, [cartOpen]);

  useEffect(() => {
    if (payPalId) {
      checkout(payPalId);
    }
  }, [payPalId]);

  const isFreeShipping = () => {
    let free = false;
    if (allDigital) {
      free = true;
    } else if ((mode === 'wholesale' || user.admin) && (address.state && (address.state?.toLowerCase().trim() === 'wa' || address.state?.trim().toLowerCase() === 'washington')) && (address.city.toLowerCase() === 'seattle') && address.country === 'US') {
      free = true;
    } else if (freeShipping) {
      free = true;
    }
    return free;
  }

  const getShipping = async () => {
    clearTimeout(debounceTimer.current);
    setShippingMethod('');
    setFetching(true);
    let request = {
      ounces: ounces,
      country: address.country,
      postalCode: address.postalCode,
      express: express,
      free: isFreeShipping(),
      digital: allDigital
    }
    controller.current?.abort();
    controller.current = new AbortController();

    debounceTimer.current = setTimeout(async () => {
      let rate = await makeRequest('shipping-rates', 'POST', request, false, { signal: controller.current.signal });
      setShipping(rate.shipmentCost);
      setShippingMethod(rate.method);
      setFetching(false);
    })
  }

  useEffect(() => {
    getShipping();
  }, [ounces, address, address.country, address.state, address.city, address.postalCode, packages, consolidateShipments, express, freeShipping])

  useEffect(() => {
    if (loaded) {
      localStorage.setItem('asterismTwoCart', JSON.stringify({ ...cart }));
    }
    setPackages(getExtraShipments(cart, mode, address.country));
    setAllDigital(isCartAllDigital(cart));
  }, [cart]);

  useEffect(() => {
    let data = localStorage.getItem('asterismTwoCart');
    if (data && JSON.parse(data)) {
      setCart(JSON.parse(data));
    }
    setLoaded(true);
  }, []);

  /**
   * Gets the user's saved address
   */
  const getDefaultAddress = async () => {
    const data = await makeRequest('address', 'GET');
    if (data.address) {
      setAddress(data.address);
    }
  }

  useEffect(() => {
    if (user.id) {
      setLoggingIn(false);
      setEmail(user.email);
    }
  }, [user]);

  /**
   * Are we okay to check out? Has the user provided all the info we need?
   * @returns {boolean} all good or not?
   */
  const checkoutCheck = () => {
    let emailValid = validateEmail(email);
    let addressValid = validateAddress(address);
    if (!emailValid) {
      setMessage({ type: 'error', label: 'Oops!', text: 'You must enter a valid email', temp: true });
      return false;
    } else if (!allDigital && !addressValid) {
      setMessage({ type: 'error', label: 'Oops!', text: 'You must complete the address form', temp: true });
      return false;
    } else if (!allDigital && address.country !== 'US' && address.country !== '' && !phone) {
      setMessage({ type: 'error', label: 'Oops!', text: 'We require a phone number for international orders', temp: true });
      return false;
    } else if (!allDigital && address.name.split(' ').length < 2) {
      setMessage({ type: 'error', label: 'Oops!', text: 'We require a first and last name for all shipments', temp: true });
      return false;
    }
    return true;
  }

  /**
   * Returns the user type for the order
   * @param {object} user is an object of user data
   * @returns {string} representing the type of user
   */
  const returnUserType = (user) => {
    return user.type === 'Retailer' ? 'wholesale' : user.type === 'Library' ? 'library' : user.type === 'Publisher' ? 'publisher' : 'retail';
  }

  const checkCoupon = async () => {
    setCodeStatus('');
    let data = await makeRequest(`coupon?code=${code}`);
    if (data.invalid) {
      setCodeStatus('invalid');
    } else {
      setCodeStatus('success');
      setCoupon(data);
    }
  }

  /** 
   * Gets the total cost minus shipping for the cart
   */
  const getCartTotal = () => {
    if (!Object.values(cart)) {
      return 0;
    }
    let theTotal = 0;
    Object.values(cart).forEach((item) => {
      let unitPrice = returnPrice(item.data, customerData.type ? 'wholesale' : mode, coupon);
      theTotal += (unitPrice * item.count)
    });
    return theTotal;
  }

  /**
   * The checkout function!
  */
  const checkout = async (payPalData = false) => {
    setUploading(true);
    if (!payPalData && collect) {
      setMessage({ type: 'info', text: 'You will be redirected to your checkout page momentarily.', label: 'Hang on!', temp: true })
    }
    // Check if the user has done all the stuff we need them to do
    let checks = checkoutCheck();
    if (!checks) {
      setUploading(false);
      analyticsEvent('checkoutCheckFail', { mode: mode });
      return;
    }
    // Prepare the big old order object
    let order = { ...defaultOrder };
    order.customer.id = customerData._id ? customerData._id : user.id ? user.id : '';
    order.customer.type = customerData.type ? customerData.type : user.type ? user.type : 'Guest';
    order.customer.email = email;
    order.customer.phone = phone;
    order.customer.address = address;
    if (payPalData) {
      order.payPalOrderId = payPalData;
    }
    order.subtotal = getCartTotal();
    order.total = getCartTotal() + shipping;
    order.hasDigital = isCartAnyDigital(cart);
    order.itemCount = cartCount;
    order.created = new Date().toISOString();
    order.type = customerData.type ? returnUserType(customerData) : mode;
    order.reference = ref;
    order.analyticsSession = session.id;
    order.testgroup = session.group;
    order.prepayDiscount = (mode === 'wholesale' || mode === 'library') && collect;
    if (order.prepayDiscount) {
      order.subtotal = order.subtotal * .92;
      order.total = order.subtotal + shipping;
    }
    // Prepare all of the packages we're gonna need to ship
    const orderData = prepareShipmentsAndSplits(order.total, cart, shipping, ounces, customerData.type ? returnUserType(customerData) : mode, consolidateShipments, order.customer.address.country, coupon, collect);
    order.shipments = orderData.shipments;
    order.coupon = coupon && coupon._id ? coupon._id : '';
    order.payments = orderData.payments;
    order.shippingSplit = orderData.shippingSplit;
    order.entities = Object.keys(order.payments);
    order.shipping.amount = shipping;
    order.shipping.method = shippingMethod;
    order.shipping.consolidate = consolidateShipments;
    // Prepare the data of the items in the cart
    order.items = prepareItems(cart, customerData.type ? returnUserType(customerData) : mode, null, order.prepayDiscount, coupon);
    let data = {
      order: order, // Order data
      createAccount: creatingAccount, // Are we creating an account?
      currentPage: window.location.href, // We pass this to Stripe in case the user cancels
      pass: pass, // If the user is creating an account, their password
      collect: (mode === 'wholesale' || customerData._id || mode === 'library') ? collect : true, // Whether or not we need a checkout page
    }
    let checkoutData = await makeRequest('checkout', 'POST', data); // Make the request!
    if (checkoutData.url) {
      analyticsEvent('checkoutClick', { mode: mode });
      window.location.href = checkoutData.url;
    } else if (checkoutData.ok) {
      setCart({});
      setCartOpen(false);
      setMessage({ type: 'success', label: 'Thank you', temp: true, text: `Thanks! You will receive ${payPalData ? 'a receipt' : 'an invoice'} for your order` });
      setUploading(false);
      setPayPalId(false);
      analyticsEvent('checkoutComplete', { mode: mode });
    } else {
      analyticsEvent('checkoutError', { mode: mode });
    }

    setUploading(false);
  }

  /**
   * Adds an item to the cart from an ID
   * @param {string} id the id of a product
   */
  const addItemToCart = async (id) => {
    let data = await getProduct(id);
    if (data && data.prices) {
      let items = { ...cart };
      items[id] = {
        count: 1,
        data: data
      }
      setCart(items);
      setNewId('');
      searchRef.current.focus();
    }
  }

  useEffect(() => {
    if (newId) {
      addItemToCart(newId);
    }
  }, [newId])

  const searchRef = useRef(null);

  return (
    <div className={`headerCart ${open ? 'headerCart__open' : ''}`}>
      <div className='roomBelowMedium roomAboveLarge'>
        {checkoutState === 'admin' &&
          <SearchControl searchref={searchRef} text='select' eagerSendId={true} selected={[]} classList={'larger'} addNew={(value) => { setNewId(typeof value == 'string' ? value : value.asterismId) }} />
        }
      </div>
      <div className='flex-layout cartHeader'>
        <h4 className='cartHeader__title'>In Your Cart</h4>
        <div>
          <button aria-label='Hide shopping cart' className='cartHideButton' onClick={() => { setCartOpen(false); asaEvent(session.id, session.group, 'cart', 'cartClose', 'cartButton', 1); }}>{`Hide >`}</button>
        </div>
      </div>
      <div className='headerCartLabels'>
        <div className='headerCartItem__image'>IMG</div>
        <div className='headerCartLabel headerCartLabel-two'>Title</div>
        <div className='headerCartLabel'>Quantity</div>
        <div className='headerCartLabel'>Price</div>
        <div className='headerCartLabel'>Remove</div>
      </div>
      <div className='headerCartList'>
        {Object.values(cart).map((item) =>
          <div key={item.data._id} className='headerCartItem'>
            <div className='headerCartItem__image'>
              {item.data.cover && <img src={item.data.cover['300']} alt={`${item.data.title} book cover`} />}
            </div>
            <div className={'headerCartItem__field headerCartItem__title'}>
              <div className='headerCartItem__content'>
                <Link to={`/product/${item.data.slug}`}>{item.data.title}</Link>
                <div className='headerCartItem__small'>{item.data.authors.length == 1 ? item.data.authors[0] : item.data.authors.length > 1 ? `${item.data.authors[0]}, et al` : ''}</div>
              </div>
            </div>
            <div className={'headerCartItem__field'}>
              <div className='headerCartItem__content'>
                <input
                  type='number'
                  disabled={isCheckingOut}
                  aria-label='Item Quantity'
                  value={item.count}
                  step={1}
                  min={1}
                  max={item.data.format && isDigitalItem(item.data.format) ? 1 : getEffectiveInventory(item.data, mode)}
                  onChange={(e) => {
                    updateQuantity(item.data._id, parseInt(e.target.value))
                  }} />
                {!isDigitalItem(item.data.format) && item.count >= getEffectiveInventory(item.data, mode) && <div className='inputControl__help'>Max {getEffectiveInventory(item.data, mode)} available</div>}
              </div>
            </div>
            <div className={'headerCartItem__field'}>
              <div className='headerCartItem__content'>
                ${returnPrice(item.data, customerData.type ? 'wholesale' : mode, coupon, mode == 'wholesale' && collect).toFixed(2)}/each
              </div>
            </div>
            <div className={'headerCartItem__field'}>
              <div className='headerCartItem__content'>
                <button aria-label='Remove item from cart' disabled={isCheckingOut} className='cartRemoveButton' onClick={() => { removeItem(item.data._id) }}><div className='closeIcon closeIcon-smaller'><Close /></div></button>
              </div>
            </div>
          </div>
        )}
      </div>

      {total <= 0 &&
        <div className='cartEmpty'><em>You have nothing in your cart.</em></div>
      }

      {total > 0 && !isCheckingOut &&
        <div>
          {user.admin &&
            <CustomerControl
              value={customer}
              change={setCustomer}
              label={'Customer'}
              help={''}
            />
          }
          {user.admin &&
            <BinaryControl
              value={freeShipping}
              name={'free-shipping'}
              change={setFreeShipping}
              label={'Free/Third-Party Shipping'}
              disabled={uploading}
            />
          }
          {!loggingIn &&
            <div>
              <TextControl
                value={email}
                name={'Email'}
                change={setEmail}
                label={'Email'}
                valid={false}
                bypass={true}
                type='text'
                disabled={uploading || user.email}
              />
              {!user.id && <div className='cartHelpText'>Already have an account? <a role='button' tabIndex={0} onClick={() => { setLoggingIn(true) }}>Login</a></div>}

              {!user.id &&
                <div>
                  <BinaryControl
                    value={creatingAccount}
                    name={'createAnAccount'}
                    change={setCreatingAccount}
                    label={'Create an account?'}
                    disabled={uploading}
                  />
                  {creatingAccount &&
                    <div>
                      <TextControl
                        value={pass}
                        name={'Password'}
                        change={setPass}
                        label={'Create Password'}
                        valid={false}
                        type='password'
                        disabled={uploading}
                      />

                      <TextControl
                        value={confirmPass}
                        name={'confirmPass'}
                        change={setConfirmPass}
                        label={'Confirm Password'}
                        valid={false}
                        type='password'
                        disabled={uploading}
                      />
                    </div>
                  }
                </div>}

              {!allDigital && <AddressForm address={address} setAddress={setAddress} uploading={uploading} />}

              {address.country !== 'US' && address.country !== '' &&
                <TextControl
                  value={phone}
                  name={'Phone'}
                  change={setPhone}
                  label={'Phone Number'}
                  valid={false}
                  type='text'
                  disabled={uploading}
                />
              }

              {packages > 0 &&
                <BinaryControl
                  value={consolidateShipments}
                  change={setConsolidateShipments}
                  label={'Ship all books at once to save on shipping?'}
                  help={`Because your order contains pre-ordered titles, there will be at least ${packages + 1} packages.`}
                />
              }
              {!allDigital && <BinaryControl
                value={express}
                change={setExpress}
                label={'Need Expedited Shipping?'}
                help={`Expedited shipments via UPS.`}
              />}
            </div>
          }

          {!user.id && loggingIn &&
            <LoginForm cancel={() => { setLoggingIn(false) }} />
          }

          {(mode === 'wholesale' || mode === 'library' || user.admin) &&
            <div>
              <TextControl
                value={ref}
                change={setRef}
                name={'Purchase Order Number'}
                label={'PO#'}
                valid={false}
                type='text'
                disabled={uploading}
              />

              <div className='discountCode flex-layout flex-layout-small'>

                <div className='flex-three'>
                  <TextControl
                    value={code}
                    change={setCode}
                    name={'Discount Code'}
                    label={'Discount Code'}
                    valid={false}
                    type='text'
                    disabled={uploading || coupon._id}
                  />
                  {codeStatus == 'success' && <div className='roomBelowMedium'><i className='fa-solid fa-check'></i> Code applied!</div>}
                  {codeStatus == 'invalid' && <div className='roomBelowMedium'><i className='fa-solid fa-x'></i> Invalid code</div>}
                </div>
                <div className='flex-one' style={{ marginTop: '24px' }}>
                  <button disabled={coupon._id ? true : false} className='buttonPrimary-small' onClick={checkCoupon}>Apply</button>
                </div>
              </div>

              <BinaryControl
                value={collect}
                change={setCollect}
                label={'Pre-pay for an additional 8% off your order'}
                disabled={uploading || customerData._id}
              />
            </div>
          }
          {!uploading && !loggingIn &&
            <div className='cartBreakdown'>
              <div className='cartBreakdown__line'><strong>Subtotal:</strong> ${(getCartTotal()).toFixed(2)}</div>
              {mode === 'wholesale' && collect && <div className='cartBreakdown__line'><strong>Prepay Discount:</strong> - ${((shipping + total) * .08).toFixed(2)}</div>}
              <div className='cartBreakdown__line'><strong>Shipping:</strong> {fetching ? 'Checking rates...' : `$${shipping.toFixed(2)}`} ({shippingMethod})</div>
              {(mode === 'wholesale' && collect)
                ? < div className='cartBreakdown__total'><strong>Total:</strong> ${(((getCartTotal()) * .92) + shipping).toFixed(2)}</div>
                : < div className='cartBreakdown__total'><strong>Total:</strong> ${getCartTotal().toFixed(2)}</div>
              }
              <div className='cartBreakdown__extra'>Note: We will collect any applicable sales taxes at the next step.</div>
            </div>
          }
          {!uploading && !loggingIn &&
            <div className='cartButtons'>
              <button className='cartButton' disabled={uploading} onClick={() => { checkout() }}>{(mode === 'wholesale' || mode === 'library' || customerData._id) && !collect ? 'Place Order' : 'Checkout'}</button>
            </div>
          }
          {/*mode !== 'wholesale' && mode !== 'library' &&
            <PayPal total={getCartTotal()} items={preparedItems} shipping={shipping} setUploading={setUploading} disabled={!email || uploading || (!isCartAllDigital(cart) && !validateAddress(address))} />
          */}
        </div>
      }
      {uploading && <div>Preparing your checkout session!</div>}



    </div >
  )
}