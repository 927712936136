import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cartState, modeState, messageState, sessionState } from '../../atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { canOrderProduct, returnPrice, isOnSale, prepareAuthorList, analyticsEvent, updatedCart, asaEvent } from '../../Utils';
import './style.css';
import showdown from 'showdown';
import moment from 'moment';
const converter = new showdown.Converter();

export default function ProductListItem({ product, featured = false, order = null, context = 'other' }) {
  const mode = useRecoilValue(modeState);
  const [cart, setCart] = useRecoilState(cartState);
  const setMessage = useSetRecoilState(messageState);
  const [expanded, setExpanded] = useState(featured);
  const [previewing, setPreviewing] = useState(false);
  const session = useRecoilValue(sessionState);


  const addToCart = () => {
    setCart(updatedCart(cart, product));
    setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'], temp: false, cart: true });
    analyticsEvent('addToCart', { product: product._id, location: 'productList', mode: mode });
    asaEvent(session.id, session.group, 'cart', `addToCart-${context}`, product._id, 1);
  }

  const prepareQuote = (quote) => {
    return converter.makeHtml(quote).replace(/[“”]/g, '').replace(/"/g, '')
  }

  useEffect(() => {
    setExpanded(featured);
  }, [featured]);

  const clickEvent = (action) => {
    asaEvent(session.id, session.group, 'productCard', action + '-' + context, product._id, 0);
  }

  return (
    <div className={`productContainer ${expanded && 'productContainer__featured'}`} style={{ order: order ? order : 1 }}>
      <div className='productItem'>
        <div className='productItem_head'>
          <div className='productDate'>{moment(product.publicationDate).format('LL')}</div>
          <div className='productIcons'>
            {product.preview && <button className='previewControl' onClick={() => { setPreviewing(true); clickEvent('preview-open') }}><i className='fa-solid fa-eye'></i></button>}
            {product.featuredQuote && <button className={`previewControl featuredNote ${expanded ? 'open' : 'closed'}`} onClick={() => { setExpanded(!expanded); clickEvent(`featured-${expanded ? 'close' : 'open'}`) }}><i className='fa-solid fa-message'></i></button>}
          </div>
        </div>
        <div className={`productItem__image ${expanded && 'featuredImage'}`}>
          {isOnSale(product, mode) && <div className='onSaleBanner'>On Sale</div>}
          {product.cover &&
            <Link to={`/product/${product.slug}`} onClick={() => { clickEvent('click-cover') }}>
              <img src={product.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={product.title} />
            </Link>
          }

        </div>
        {expanded && product.featuredQuote && <div className='productItem__featuredPull'>
          <div className='arrowRight'></div>
          <div className={`featuredQuotation ${product.featuredQuote.length < 160 && 'larger'}`} dangerouslySetInnerHTML={{ __html: prepareQuote(product.featuredQuote) }}></div>
          <div className='featuredAttribution'>{product.featuredAttribution}</div>
        </div>}
        <div className='productItem__info'>
          <div className='productItem__left'>
            <div className='productItem__publisher'><Link to={`/publisher/${product.publisherData.slug}`} onClick={() => { clickEvent('click-publisher') }}>{product.publisherData.name}</Link></div>
            <Link to={`/product/${product.slug}`} className='productItem__title' onClick={() => { clickEvent('click-title') }}>{product.title}</Link>
            <div className='productItem__authors'>{prepareAuthorList(product)}</div>
          </div>
          <div className='productItem__right'>
            <div className='productItemPrice'>
              <div className='productItemPrice__retail'>${returnPrice(product, mode).toFixed(2)}</div>
              {canOrderProduct(product, mode) && mode === 'wholesale' && <div className='productItemPrice__wholesale'>{product.prices.wholesaleDiscount}% off</div>}
            </div>
          </div>
        </div>
        <div className='productItem__bottom'>
          <div>
            {product.format && product.format !== 'Paperback' && product.format !== 'Other' && <span className='productItemFormat'>{product.format}</span>}
            {product.format && product.format !== 'Paperback' && product.format !== 'Other' && product.inventory.asterism < 5 && product.inventory.asterism > 0 && ' | '}
            {product.inventory.asterism < 5 && product.inventory.asterism > 0 && <span className='lowStock'>{product.inventory.asterism} cop{product.inventory.asterism === 1 ? 'y' : 'ies'} left</span>}
          </div>
          <div className='productItem__controls'>
            <button className='productControl__add' disabled={!canOrderProduct(product, mode)} onClick={addToCart}>Add <i className='fa-solid fa-plus'></i></button>
            <Link className='productControl__more' onClick={() => { clickEvent('click-moreArrow') }} to={`/product/${product.slug}`}><i className='fa-solid fa-arrow-right'></i></Link>
          </div>
        </div>
      </div>
      {previewing && <PreviewModal product={product} close={() => { setPreviewing(false) }} />}
    </div>
  )
}

function PreviewModal({ product, close }) {
  return (
    <div className='previewModal__wrapper'>
      <div className='previewModal__content'>
        <div className='previewModal__header'>
          <div className='previewHeader_data'>
            <div className='productItem__publisher'><Link to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link></div>
            <Link to={`/product/${product.slug}`} className='productItem__title'>{product.title}</Link>
            <div className='productItem__authors'>{prepareAuthorList(product)}</div>
          </div>
          <div>
            <button className='closeButton' onClick={close}><i className='fa-solid fa-xmark'></i></button>
          </div>
        </div>
        <object
          data={product.preview}
          width="100%"
          height="92%">
        </object>
      </div>
    </div>
  )
}