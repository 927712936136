import { TextControl, TextAreaControl } from '../InputControls';
import AddressForm from '../AddressForm';
import React, { useState, useEffect } from 'react';
import { makeRequest } from '../../Utils';
import { messageState } from '../../atoms';
import { useRecoilState } from 'recoil';

export default function ApplyFormPublisher() {
  const [pressName, setPressName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useRecoilState(messageState);
  const [phone, setPhone] = useState('');
  const [sent, setSent] = useState(false);
  const [location, setLocation] = useState('')
  const [website, setWebsite] = useState('');
  const [application, setApplication] = useState('')

  const sendMessage = async () => {
    let data = {
      name: pressName,
      contactName: name,
      contactEmail: email,
      contactPhone: phone,
      application: application,
      website: website,
      location: location
    }
    if (!email || !name || !pressName || !website || !application) {
      setMessage({ type: 'error', label: 'oops!', text: 'Please fill out all required fields', temp: true })
      return
    }
    const request = await makeRequest('pub-application', 'POST', data);
    if (request.ok) {
      setSent(true);
    }
  }

  if (sent) {
    return (
      <div className='contactForm'>
        <h3 className='contactForm__header'>Apply for a Publisher Account</h3>
        <p>Thanks for your application! We'll get back to you shortly!</p>
      </div>
    )
  }

  return (
    <div className='contactForm'>
      <h3 className='contactForm__header'>Apply for a Publisher Account</h3>
      <p>We aim to get back to you within a week.</p>
      <TextControl
        value={pressName}
        change={(value) => {
          setPressName(value)
        }}
        label={`Publisher Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={website}
        change={(value) => {
          setWebsite(value)
        }}
        label={`Publisher Website`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={name}
        change={(value) => {
          setName(value)
        }}
        label={`Your Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={email}
        change={(value) => {
          setEmail(value)
        }}
        label={`Your Email`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={phone}
        change={(value) => {
          setPhone(value)
        }}
        label={`Your Phone Number`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextAreaControl
        value={application}
        change={setApplication}
        label={`Tell us about your business!`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={'When did the press begin? How many titles per year do you publish? What is your current distribution arrangement? Etc.'}
        rows={3}
      />

      <TextControl
        value={location}
        change={(value) => {
          setLocation(value)
        }}
        label={`Where are you based?`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <button className='buttonPrimary' onClick={sendMessage}>Submit</button>
    </div>
  )
}