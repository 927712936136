import { useEffect, useState } from 'react'
import { modeState, sessionState, userState } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { asaPageview, asaSession, getCountry, uuid } from '../Utils';
import { useLocation } from 'react-router-dom';

export function useTestGroup() {
  const [testGroup, setTestGroup] = useState(null);
  const [session, setSession] = useRecoilState(sessionState);
  const mode = useRecoilValue(modeState);

  const location = useLocation();

  useEffect(() => {
    if (session && session.id) {
      asaPageview(session.id, window.location.pathname, window.location.search, {});
    }
  }, [location, session]);

  const isEven = (n) => {
    return n % 2 === 0;
  }

  // Check for existing group
  let stored = localStorage.getItem('asterismTestGroup');
  let seed = new Date().getTime();
  let group = stored ? stored : isEven(seed) ? 'A' : 'B';
  localStorage.setItem('asterismTestGroup', group);
  let sessionId = uuid() + '+' + new Date().getTime();
  let ref = document.referrer ? new URL(document.referrer).hostname : '';
  let campaign = '';
  /* Get campaign */
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('campaign')) {
    campaign = urlParams.get('campaign');
    urlParams.delete('campaign');
  }
  if (urlParams.get('setTestGroup')) {
    group = urlParams.get('setTestGroup');
    urlParams.delete('setTestGroup');
    localStorage.setItem('asterismTestGroup', group);
  }
  /* End get campaign */
  let country = getCountry();
  if (!session.id) {
    setSession({
      id: sessionId,
      country: country,
      group: group,
      campaign: campaign,
      browserwidth: window.innerWidth,
    });
    asaSession(sessionId, mode, group, country, window.innerWidth, ref, campaign);
  }

  return testGroup;
}