import React, { useEffect, useState } from 'react';
import { TextControl, CountryControl } from '../InputControls';
import { updateObject } from '../../Utils';

export default function AddressForm({ address, setAddress, uploading = false, label = 'Shipping', attn = false }) {

  return (
    <div>
      <div className=''>
        <TextControl
          value={address.name}
          name={'Name'}
          change={(value) => { updateObject(address, 'name', value, setAddress) }}
          label={'Name'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />
        <TextControl
          value={address.company}
          name={'Company'}
          change={(value) => { updateObject(address, 'company', value, setAddress) }}
          label={'Company'}
          valid={false}
          bypass={true}
          type='text'
          disabled={uploading}
        />
        <TextControl
          value={address.lineOne}
          name={'Address Line One'}
          change={(value) => { updateObject(address, 'lineOne', value, setAddress) }}
          label={'Address Line One'}
          valid={false}
          autocomplete={'address-line1'}
          type='text'
          bypass={true}
          disabled={uploading}
        />
        <TextControl
          value={address.lineTwo}
          name={'Address Line Two'}
          change={(value) => { updateObject(address, 'lineTwo', value, setAddress) }}
          label={'Address Line Two'}
          autocomplete={'address-line2'}
          valid={false}
          type='text'
          bypass={true}
          disabled={uploading}
        />
      </div>
      <div className='flex-layout flexColMobile'>
        <div className='flex-one'>
          <TextControl
            name={'Address City'}
            value={address.city}
            autocomplete={'address-level2'}
            change={(value) => { updateObject(address, 'city', value, setAddress) }}
            label={'City'}
            valid={false}
            type='text'
            bypass={true}
            disabled={uploading}
          />

          <TextControl
            value={address.postalCode}
            name={'Address Postal Code'}
            change={(value) => { updateObject(address, 'postalCode', value, setAddress) }}
            label={'Postal Code'}
            autocomplete={'postal_code'}
            valid={false}
            type='text'
            bypass={true}
            disabled={uploading}
          />

        </div>
        <div className='flex-one'>
          <TextControl
            name={'Address State'}
            autocomplete={'address-level1'}
            value={address.state}
            change={(value) => { updateObject(address, 'state', value, setAddress) }}
            label={'State/Region'}
            valid={false}
            type='text'
            bypass={true}
            disabled={uploading}
          />
          <CountryControl
            value={address.country}
            label={'Country'}
            change={(value) => { updateObject(address, 'country', value, setAddress) }}
          />
        </div>
      </div>

    </div>
  )
}